import { render, staticRenderFns } from "./increase.vue?vue&type=template&id=671a59bf&scoped=true"
import script from "./increase.vue?vue&type=script&lang=js"
export * from "./increase.vue?vue&type=script&lang=js"
import style0 from "./increase.vue?vue&type=style&index=0&id=671a59bf&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "671a59bf",
  null
  
)

export default component.exports