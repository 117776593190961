<template>
  <div>
    <div v-if="model===false">
      <div class="totalmakeHole">
        <div class="makeHole1">
          <div class="screen1">
            <div class="topback">
            <headtip @showDialog="showPartner"></headtip>
            </div>
            <div class="layer2">
              <div class="title1">ABOUT US</div>
              <div class="title1">关于我们</div>
<!--              <div class="title2">安东油气田开发研究院</div>-->

            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
          <div class="screenmake0">
            <div class="heighLight"> </div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
<!--                    <img loading="lazy"  src="../assets/groundabout2.webp">-->
                    <video id="myVideo" ref="myVideo" width="99%" height="100%" style="border-radius: 9px" controls>
                      <source src="@/video/introduce.mp4" type="video/mp4">

                    </video>
                  </div>

                  <div class="titleDesign">
                    <div>
                      <div class="title2">
                        安东油藏地质研究院以油藏地质和工程技术相结合为核心，配合数字化、可视化手段，向油田客户提供具有增产特色的方案定制化服务，提高开发效率，帮助客户精准命中目标油气藏，使客户油藏资产价值最大化。
                        业务包括油藏地质研究技术服务、地质工程技术服务、增产技术服务、油藏数字化服务以及整体解决方案增产服务。
                         </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="screenmake0" style="padding-top: 0">
            <div class="heighLight"> </div>
            <div class="grid">
              <div class="card">
                <div class="back">


                  <div class="titleDesign">
                    <div>
                      <div class="title2">
                        具有学科齐全、专业配套的地质工程一体化技术和管理团队，提供地质工程一体化风险总包服务，现有团队成员103人（6博、32硕），涵盖地震解释、储层反演、地质力学、三维地质建模、数值模拟、生产动态、钻井、储层改造、试油试气等专业，骨干技术人员多为中石油、中石化油田公司专家，20余年工作经验，行业认可度高。   </div>
                      <div class="title4">
                        "3+1"服务模式
                      </div>
                      <div class="title1">
                        <img loading="lazy" style="border-radius: 9px" src="../assets/ground31.png">
                      </div>
                      <div class="title3" >
                        <img loading="lazy" style="border-radius: 9px" src="../assets/groundabout3.png">
                      </div>
                    </div>
                  </div>
                  <div class="imgDesign">
                    <!--                    <img loading="lazy"  src="../assets/groundabout2.webp">-->
                    <img src="@/assets/aboutBack.png">
                  </div>
                </div>
              </div>
            </div>

          </div>
<!--          <div class="screenmake1">-->
<!--            <div class="heighLight"> 主营业务</div>-->
<!--            <div class="grid">-->
<!--              <div class="card">-->
<!--                <div class="back" style="background:linear-gradient(to bottom,rgb(249,251,233) 0%,rgb(248,248,248)100%);">-->
<!--                  <div class="imgDesign">-->
<!--                    <img loading="lazy"  src="../assets/groundabout4.png">-->
<!--                  </div>-->
<!--                  <div class="titleDesign" style="color: rgb(154,131,53) ">-->
<!--                    <div>-->
<!--                      <div class="title1">油藏地质研究服务-->
<!--                      </div>-->
<!--                      <div class="title2">-->
<!--                        以油藏地质研究为核心，应用非常规油气储层评价、裂缝预测、地质力学分析等技术，在油气田勘探、评价、开发等过程为客户提供服务。-->
<!--                        <br>业务主要包括：构造精细解释、储层预测、地质评价、油气藏精细描述、开发方案研究、开发动态分析等服务。  </div>-->
<!--                    </div>-->
<!--                  </div>-->

<!--                </div>-->
<!--              </div>-->
<!--              <div class="card">-->
<!--                <div class="back" style="background:linear-gradient(to bottom,rgb(207,225,255) 0%,rgb(248,248,248)100%);">-->
<!--                  <div class="imgDesign">-->
<!--                    <img loading="lazy"  src="../assets/groundabout5.png">-->

<!--                  </div>-->
<!--                  <div class="titleDesign" style="color: rgb(35,105,219) ">-->
<!--                    <div>-->
<!--                      <div class="title1">地质工程技术服务-->
<!--                      </div>-->
<!--                      <div class="title2">-->
<!--                        依托地质工程服务云平台，工程与地质相结合，立足油气田已有成果，结合基础油藏地质研究，提供实时、动态的优化建议。与客户共享应用，合作推广，提供量身定做的工程地质技术服务，打通地质向工程延伸的“最后一公里”。-->
<!--                        <br>业务主要包括：工程实时迭代技术服务、地质工程跟踪模拟技术服务、现场地质工程师服务及数据支持服务。   </div>-->
<!--                    </div>-->
<!--                  </div>-->

<!--                </div>-->
<!--              </div>-->
<!--              <div class="card">-->
<!--                <div class="back" style="background:linear-gradient(to bottom,rgb(215,244,250) 0%,rgb(248,248,248)100%);">-->
<!--                  <div class="imgDesign">-->
<!--                    <img loading="lazy"  src="../assets/groundabout6.png">-->

<!--                  </div>-->
<!--                  <div class="titleDesign" style="color: rgb(26,167,172) ">-->
<!--                    <div>-->
<!--                      <div class="title1">增产技术服务-->
<!--                      </div>-->
<!--                      <div class="title2">-->
<!--                        以油藏地质研究为基础，为各类油气藏制定针对性的增产技术解决方案，为客户提供集方案设计、材料优选、现场实施到压后评估的一站式增产技术服务。-->
<!--                        <br>业务包括：油气藏压裂技术及系列产品服务、增产措施效果监测评估技术服务、老井增产技术及系列产品服务。-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->

<!--                </div>-->
<!--              </div>-->
<!--              <div class="card">-->
<!--                <div class="back" style="background:linear-gradient(to bottom,rgb(255,220,201) 0%,rgb(248,248,248)100%);">-->
<!--                  <div class="imgDesign">-->
<!--                    <img loading="lazy"  src="../assets/groundabout7.png">-->

<!--                  </div>-->
<!--                  <div class="titleDesign" style="color: rgb(225,94,2) ">-->
<!--                    <div>-->
<!--                      <div class="title1">整体解决方案增产服务-->
<!--                      </div>-->
<!--                      <div class="title2">-->
<!--                        针对非常规油气藏，采用系统的创新方法，以提高产量为核心，地质工程相结合为特色，集成应用先进的设计理念、新工艺和新技术，创新商务合同模式与生产组织模式，为客户提供整体解决方案增产服务，帮助客户实现油藏价值最大化。   </div>-->
<!--                  </div>-->
<!--                  </div>-->

<!--                </div>-->
<!--              </div>-->
<!--              <div class="card">-->
<!--                <div class="back" style="background:linear-gradient(to bottom,rgb(255,217,245) 0%,rgb(248,248,248)100%);">-->
<!--                  <div class="imgDesign">-->
<!--                    <img loading="lazy"  src="../assets/groundabout8.png">-->

<!--                  </div>-->
<!--                  <div class="titleDesign" style="color: rgb(203,65,146) ">-->
<!--                    <div>-->
<!--                      <div class="title1">油气田开发动态监测GPTs-->
<!--                      </div>-->
<!--                      <div class="title2">-->
<!--                        通过实时数据采集、分析与处理、预测建模、可视化展示和决策支持，帮助油气田管理者优化资源配置、提高生产效率，并有效识别和管理潜在风险。保障生产安全，促进环境保护，确保信息及时传递和处理，为油气田开发提供全方位、实时动态监测与分析的解决方案，推动油气田开发的数字化转型和可持续发展。  </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="screenmake0">-->
<!--            <div class="heighLight"> 组织机构</div>-->
<!--            <div class="grid" style="margin-top: 4vh">-->
<!--              <div style="width: 80%;height: fit-content;margin:0 auto">-->
<!--                <img loading="lazy"  src="../assets/groundabou10t.png">-->
<!--              </div>-->
<!--            </div>-->

<!--          </div>-->
<!--          <div class="screenmake2">-->
<!--            <div class="heighLight"> 发展历程</div>-->
<!--            <div class="grid">-->
<!--              <div style="width: 100%;height: fit-content;margin:0 auto">-->
<!--                <img loading="lazy"  src="../assets/groundabout11.png">-->
<!--              </div>-->
<!--            </div>-->

<!--          </div>-->
<!--          <div class="screenmake3">-->
<!--            <div class="heighLight"> 人才招聘</div>-->
<!--            <div class="grid">-->
<!--              <div class="card">-->
<!--                <div class="back">-->
<!--                  <div class="imgDesign">-->
<!--                    <img loading="lazy"  src="../assets/groundabout9.png">-->

<!--                  </div>-->

<!--                  <div class="titleDesign">-->
<!--                    <div style="list-style: none;border-radius: 9px">-->
<!--                      <div class="more" style="color: white;font-weight: bold;border-bottom: white 1px solid" @click="skipto">安东招聘</div>-->
<!--                      <dv-scroll-board  @click="skipto" class="dv-scroll-board"  :config="config"  />-->
<!--                      <div class="more" style="color: white;font-weight: bold;"  @click="skipto">更多...</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
          <bottomTip @showNet="showNet" @showProduct="showProduct"  @showComment="showComment"  @showDialogBottom="scroll"></bottomTip>

        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
                   <netFeedback  :dialogVisible="dialogNet"
              @hideDialog="hideNet"></netFeedback>
        <productFeedback
            :dialogVisible="dialogProduct"
            @hideDialog="hideProduct"
        ></productFeedback>

        <commentFeedback
            :dialogVisible="dialogComment"
            @hideDialog="hideComment"
        ></commentFeedback>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
    <div v-else>
      <div class="totalmakeHoleModel">
        <div class="makeHole1">
          <div class="screen1">
            <div style="margin:0 15px;">
              <div class="topback">
            <headtip @showDialog="showPartner"></headtip>
            </div>
            </div>

            <!--      logo  you气通GPT社区 合作伙伴  登录-->

            <!--       地质工程云平台GPT 全球油气行业最专业的、开放的大模型人工智能云平台-->
            <div class="layer2">
              <div class="title1">关于我们</div>
            </div>
            <div class="titlle3" @click="clickConsult('地质工程云平台')">
              产品咨询
            </div>
            <!--      平台大图-->
            <div class="layer3"></div>
          </div>
          <div class="screenmake0">
            <div class="heighLight"> 企业简介</div>
            <div class="grid">
              <div class="card">
                <div class="back">
                  <div class="imgDesign">
<!--                    <img loading="lazy"  src="../assets/groundabout2.webp">-->
                    <video id="myVideo" ref="myVideo" width="99%" height="100%" style="border-radius: 9px" controls>
                      <source src="@/video/introduce.mp4" type="video/mp4">

                    </video>
                  </div>

                  <div class="titleDesign">
                    <div>
                      <div class="title2" style="width:100%;margin-top: 5px">
                        安东油藏地质研究院以油藏地质和工程技术相结合为核心，配合数字化、可视化手段，向油田客户提供具有增产特色的方案定制化服务，提高开发效率，帮助客户精准命中目标油气藏，使客户油藏资产价值最大化。
                        业务包括油藏地质研究技术服务、地质工程技术服务、增产技术服务、油藏数字化服务以及整体解决方案增产服务。
                      </div>
                      <div class="title4">
                        "3+1"服务模式
                      </div>
                      <div class="title1" style="width:95%;margin:15px auto 5px auto">
                        <img loading="lazy" style="border-radius: 9px" src="../assets/ground31.png">
                      </div>
                      <div class="title2" >
                        <img loading="lazy" style="border-radius: 9px" src="../assets/groundabout3.png">
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="screenmake1">
            <div class="heighLight"> 主营业务</div>
            <div class="grid">
              <div class="card">
                <div class="back" style="background:linear-gradient(to bottom,rgb(249,251,233) 0%,rgb(248,248,248)100%);">
                  <div class="imgDesign">
                    <img loading="lazy"  src="../assets/groundabout4.png">
                  </div>
                  <div class="titleDesign" style="color: rgb(154,131,53) ">
                    <div>
                      <div class="title1">油藏地质研究服务
                      </div>
                      <div class="title2">
                        以油藏地质研究为核心，应用非常规油气储层评价、裂缝预测、地质力学分析等技术，在油气田勘探、评价、开发等过程为客户提供服务。
                        <br>业务主要包括：构造精细解释、储层预测、地质评价、油气藏精细描述、开发方案研究、开发动态分析等服务。  </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="card">
                <div class="back" style="background:linear-gradient(to bottom,rgb(207,225,255) 0%,rgb(248,248,248)100%);">
                  <div class="imgDesign">
                    <img loading="lazy"  src="../assets/groundabout5.png">

                  </div>
                  <div class="titleDesign" style="color: rgb(35,105,219) ">
                    <div>
                      <div class="title1">地质工程技术服务
                      </div>
                      <div class="title2">
                        依托地质工程服务云平台，工程与地质相结合，立足油气田已有成果，结合基础油藏地质研究，提供实时、动态的优化建议。与客户共享应用，合作推广，提供量身定做的工程地质技术服务，打通地质向工程延伸的“最后一公里”。
                        <br>业务主要包括：工程实时迭代技术服务、地质工程跟踪模拟技术服务、现场地质工程师服务及数据支持服务。   </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="card">
                <div class="back" style="background:linear-gradient(to bottom,rgb(215,244,250) 0%,rgb(248,248,248)100%);">
                  <div class="imgDesign">
                    <img loading="lazy"  src="../assets/groundabout6.png">

                  </div>
                  <div class="titleDesign" style="color: rgb(26,167,172) ">
                    <div>
                      <div class="title1">增产技术服务
                      </div>
                      <div class="title2">
                        以油藏地质研究为基础，为各类油气藏制定针对性的增产技术解决方案，为客户提供集方案设计、材料优选、现场实施到压后评估的一站式增产技术服务。
                        <br>业务包括：油气藏压裂技术及系列产品服务、增产措施效果监测评估技术服务、老井增产技术及系列产品服务。
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="card">
                <div class="back" style="background:linear-gradient(to bottom,rgb(255,220,201) 0%,rgb(248,248,248)100%);">
                  <div class="imgDesign">
                    <img loading="lazy"  src="../assets/groundabout7.png">

                  </div>
                  <div class="titleDesign" style="color: rgb(225,94,2) ">
                    <div>
                      <div class="title1">整体解决方案增产服务
                      </div>
                      <div class="title2">
                        针对非常规油气藏，采用系统的创新方法，以提高产量为核心，地质工程相结合为特色，集成应用先进的设计理念、新工艺和新技术，创新商务合同模式与生产组织模式，为客户提供整体解决方案增产服务，帮助客户实现油藏价值最大化。   </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="card">
                <div class="back" style="background:linear-gradient(to bottom,rgb(255,217,245) 0%,rgb(248,248,248)100%);">
                  <div class="imgDesign">
                    <img loading="lazy"  src="../assets/groundabout8.png">

                  </div>
                  <div class="titleDesign" style="color: rgb(203,65,146) ">
                    <div>
                      <div class="title1">油气田开发动态监测GPTs
                      </div>
                      <div class="title2">
                        通过实时数据采集、分析与处理、预测建模、可视化展示和决策支持，帮助油气田管理者优化资源配置、提高生产效率，并有效识别和管理潜在风险。保障生产安全，促进环境保护，确保信息及时传递和处理，为油气田开发提供全方位、实时动态监测与分析的解决方案，推动油气田开发的数字化转型和可持续发展。  </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="screenmake0">
            <div class="heighLight"> 组织机构</div>
            <div class="grid" style="margin-top: 4vh">
              <div style="width: 100%;height: fit-content">
                <img loading="lazy"  src="../assets/groundabou10t.png">
              </div>
            </div>

          </div>
          <div class="screenmake2">
            <div class="heighLight"> 发展历程</div>
            <div class="grid">
              <div style="width: 100%;height: fit-content">
                <img loading="lazy"  src="../assets/groundabout11.png">
              </div>
            </div>

          </div>
          <div class="screenmake3">
            <div class="heighLight"> 人才招聘</div>
            <div class="grid">
              <div class="card">
                <div class="back">

                  <div class="titleDesign">
                    <div style="list-style: none;border-radius: 9px">
                      <dv-scroll-board  @click="skipto" class="dv-scroll-board"   :config="configmobile"  />
                      <div class="more" @click="skipto">更多...</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div ref="bottom">
            <bottomTip @showNet="showNet" @showProduct="showProduct"  @showComment="showComment"  @showDialogBottom="scroll"></bottomTip>

          </div>

        </div>
        <partnerTip
            :dialogVisible="dialogconsult"
            @hideDialog="hide"
        ></partnerTip>
                   <netFeedback  :dialogVisible="dialogNet"
              @hideDialog="hideNet"></netFeedback>
        <productFeedback
            :dialogVisible="dialogProduct"
            @hideDialog="hideProduct"
        ></productFeedback>

        <commentFeedback
            :dialogVisible="dialogComment"
            @hideDialog="hideComment"
        ></commentFeedback>
        <consultTip
            :dialogVisible="dialogVisible"
            :thisTitle="title"
            @hideDialog="hideDialog"
        ></consultTip>
      </div>
    </div>
  </div>

</template>
<script>
import commentFeedback from "@/components/commentFeedback.vue";
import productFeedback from "@/components/productFeedback.vue";
import netFeedback from "@/components/netFeedback.vue";
import headtip from "@/views/headTip.vue";
import bottomTip from "@/views/bottomTip.vue";
import consultTip from "@/components/consultTip.vue";
import PartnerTip from "@/components/partnerTip.vue";
import {shareUrl} from "@/utils/vxshare";
export default {

  name: "",

  props: [],
  components: {commentFeedback,
    productFeedback,
    netFeedback,PartnerTip, consultTip, headtip,bottomTip},

  data() {
    return {      dialogComment:false,
      dialogProduct:false,
      dialogNet:false,
      title:'',
      token: localStorage.getItem("Zyaccess_token"),
      configmobile: {
        headerBGC:'rgba(16,31,115,0.5)',
        oddRowBGC:'rgba(75,173,197,0.1)',
        evenRowBGC:'rgba(48,92,194,0.1)',
        rowNum:6,
        header: ['职位名称', '所属部门', '工作地点'],
        align:['center','center','center'],
        data: [],
        waitTime:2000,
      },
      config: {
        headerBGC:'rgba(16,31,115,0.5)',
        oddRowBGC:'rgba(75,173,197,0.1)',
        evenRowBGC:'rgba(48,92,194,0.1)',
        rowNum:4,
        header: ['职位名称', '所属部门', '工作地点'],
        align:['center','center','center'],
        data: [
          ["压裂设计工程师",
           " 油气田开发研究院",
           " 成都/郑州"],
          ["油气藏工程技术专家",
            " 油气田开发研究院",
            " 库尔勒"],
              ["开发地质技术专家",
              " 油气田开发研究院",
              " 库尔勒"],
              ["地震解释技术专家",
              " 油气田开发研究院",
              " 库尔勒"],
              ["压裂设计工程师",
              " 油气田开发研究院",
              " 成都/郑州"]

        ],
        waitTime:2000,
      },
      pageTitle: '关于我们-地质工程云平台GPT',
      pageDescription: '安东油藏地质研究院以油藏地质和工程技术相结合为核心，配合数字化、可视化手段，向油田客户提供具有增产特色的方案定制化服务，提高开发效率，帮助客户精准命中目标油气藏，使客户油藏资产价值最大化。 业务包括油藏地质研究技术服务、地质工程技术服务、增产技术服务、油藏数字化服务以及整体解决方案增产服务。',
      pageKeywords: '地质工程云平台GPT,关于我们',
      model:false,
      dialogconsult:false,
      dialogVisible:false,

    }

  },


  methods: {    hideComment() {
      localStorage.setItem("skip", 0)
      this.dialogComment = false;
    },
    hideProduct(){
      localStorage.setItem("skip", 0)
      this.dialogProduct = false;
    },
    hideNet(){
      localStorage.setItem("skip", 0)
      this.dialogNet = false;
    },showComment(){
      this.dialogComment=true
      // console.log("chufa")
    },
    showProduct(){
      this.dialogProduct=true
    },
    showNet(){
      this.dialogNet=true
    },
    skipto(config){
      console.log('config', config.row)
    window.open("https://wecruit.hotjob.cn/SU64db9dff6202cc36e28c3a96/pb/index.html#/")
    },
    getTalent(){
      this.$axios
          .get("/info/job/list",{
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          })
          .then((res) => {
            this.job = res.data.rows
            console.log("--------ren")
           // const newData = this.job.map(item => [item.name, item.dept, item.address]);
            // 清除原有数据
            // this.config.data = [];
            // // const dataItem = ['压裂设计工程师', '油气田开发研究院', '成都/郑州'];
            // for(var i=0;i<newData.length;i++){
            //   this.config.data.push(newData[i]);
            // }
            // this.config = { ...this.config }
            // this.configmobile.data = [];
            // // const dataItem = ['压裂设计工程师', '油气田开发研究院', '成都/郑州'];
            // for(var j=0;j<newData.length;j++){
            //   this.configmobile.data.push(newData[j]);
            // }
            //
            // this.configmobile = { ...this.configmobile }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    clickConsult(item){

      this.title=item
      this.dialogVisible = true;
      sessionStorage.setItem('title',item)
    },
    hide(){
      this.dialogconsult=false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    showPartner(){
      this.dialogconsult=true;
    },
    skipp3() {
      const element = this.$refs.bottom;
      const rect = element.offsetTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);

      this.$nextTick(() => {
        this.$refs.totalModel.scrollTop = rect
      });
    },
    setMetaTags() {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', this.pageDescription);
      }

      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', this.pageKeywords);
      }
    },
  },

  mounted() {
    const videoEl = this.$refs.myVideo;
    videoEl.play();
    document.title=this.pageTitle;
    this.setMetaTags();
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if(screenWidth<=700){
      this.model=true
    }else{
      this.model=false
    }

    if (localStorage.getItem("skip") === '3') {
      this.$nextTick(() => {
        this.skipp3();
      });
    }
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  created() {
    this.getTalent()
    localStorage.setItem("home",0)
    let url = window.location.href
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        pageTitle: '关于我们-地质工程云平台GPT',
        pageDescription: '安东油藏地质研究院以油藏地质和工程技术相结合为核心，配合数字化、可视化手段，向油田客户提供具有增产特色的方案定制化服务，提高开发效率，帮助客户精准命中目标油气藏，使客户油藏资产价值最大化。 业务包括油藏地质研究技术服务、地质工程技术服务、增产技术服务、油藏数字化服务以及整体解决方案增产服务。',
        pageKeywords: '地质工程云平台GPT,关于我们',

      };
      shareUrl(shareData);
    }, 1000);
  },
}

</script>


<style type="text/scss" lang="scss" scoped>
.totalmakeHole{
  background: white;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    //height: 100vh;
    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 70vh;

      padding: 0 0 0 0;
      background-image: url("../assets/groundabout.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      .topback {
        position: fixed;
        z-index: 9;
        width: 99vw;
        padding: 1vh  0;
        background-color: rgb(52, 48, 48,0.3);
      }
      .layer2 {
        position: absolute;
        top: 50%;
        transform: translateY(-43%);
        left: 6.5vw;

        .title1 {
          width: fit-content;
          height: fit-content;
          font-size: 2.5vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #2f6eff;
          line-height: 7vh;
        }

        .title2 {
          margin-top: 5vh;
          width: 40vw;
          height: fit-content;
          text-align: justify;
          font-size: 1.0597920277vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: black;
          line-height: 4.31520395550062vh;
        }
        .titlle3{
          margin-top: 8vh;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1vw;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #ffffff;
          line-height: 3.3374536465vh;
          text-align: center;
          border-radius: 53px;
          width: fit-content;
          padding: 2.4624227441vh 1.1989601386vw;
          height: 4.5624227441vh;
          background: #2f6eff;
          border: #2f6eff 1px solid;

        }
        .titlle3:hover{

          color: #2168DB;
          border: #2168DB 1px solid;
          background: rgba(255, 255, 255, 0.5);
        }
      }


      //.layer3 {
      //  position: absolute;
      //  top: 5.06798516687268vh;
      //  right: 11.61178509532062vw;
      //  width: 47.42923165800116vw;
      //  height: 72.80593325092707vh;
      //  background-image: url("../assets/GPTplant.png");
      //  background-size: 100% auto;
      //  background-position: center;
      //  background-repeat: no-repeat;
      //  background-color: transparent;
      //  opacity: 1;
      //}
    }
    .screenmake0{
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        //height:8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        color: #3a75fe;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;
        width: fit-content;
        //border-bottom: #5798f2 5px solid;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            background: white;
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height:fit-content ;
            overflow:hidden;
            .imgDesign{
              width:60%;
              height:fit-content;
              opacity: 1;
            }
            .titleDesign{
              padding: 0 4.5248454883vw 2.5248454883vh 2%;
              margin:auto 1vw auto auto;
              color: #3a75fe;
              width: 45%;
              float: right;
              position: relative;
              .title1{
                position: absolute;
                margin: 5vh auto;
                width: 82%;
                height: fit-content;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }
              .title2{

                text-align: justify;
                width: 100%;
                margin: 3vh auto 0 auto;
                line-height: 5vh;
                font-size: 1.2vw;;
              }

              .title3{
                margin: 10.5vh auto;
                width: 70%;
                display: grid;
                grid-template-columns: minmax(0,1fr);
                line-height: 3.194066749vh;
                font-size: 0.9vw;;
              }
              .title4{
                position: absolute;
                margin: 5vh auto;
                left:50%;
                transform: translateX(-20%);
                width: 82%;
                z-index: 99999;
                color: white;
                color: white;
                height: fit-content;
                font-size:1.5vw;
                font-weight: bold;
              }

            }
          }
        }


      }
    }
    .screenmake1{
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: transparent;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        color: #3a75fe;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;
        width: fit-content;
        //border-bottom: #5798f2 5px solid;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
      .grid{
        margin-top:4.78739184177998vh ;
        position: relative;
        display: grid;
        grid-template-columns:minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 1.5vw;
        .card{
          transition:transform 0.3s ease-out;
          //cursor: pointer;
          background-color: white;
          border-radius: 9px;
          width: 100%;
          height:fit-content ;

          opacity: 1;

          .back{
            background: transparent;
            border-radius: 9px;
            position: relative;
            height:60vh ;
            border: 1px solid white;
            overflow:hidden;
            .imgDesign{
              width:20%;
              margin: 3vh auto 0 auto;
              height:fit-content;
              opacity: 1;
            }
            .titleDesign{
              padding:1.5vh 0.5vw;
              margin:0 1vw auto auto;
              color: black;
              width:100%;
              .title1{
                margin: 0 auto 0 auto;
                width: fit-content;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }
              .title2{
                text-align: justify;
                width: 90%;
                margin: 1.5vh auto 0 auto;
                line-height: 3.194066749vh;
                font-size: 0.9vw;;
              }

            }

          }
        }
        .card:hover{
          .imgDesign {
            transform:scale(1.12);
          }
        }

      }

    }
    .screenmake2{
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: transparent;

      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        color: #3a75fe;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;
        width: fit-content;
        //border-bottom: #5798f2 5px solid;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
      .grid{
        margin-top:4.78739184177998vh ;
        position: relative;
        display: grid;
        grid-template-columns:minmax(0, 1fr);
        column-gap: 0;
        row-gap: 2vh;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            background: transparent;
            border-radius: 9px;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height:66.87144622991347vh ;
            overflow:hidden;
            .imgDesign{
              width:15%;
              margin: 5% auto 0 auto;
              height:fit-content;
              opacity: 1;
            }
            .titleDesign{
              padding: 4.5248454883vh 2.5248454883vw 2.5248454883vh 2%;
              margin:0 1vw auto auto;
              color: black;
              width: 45%;
              float: right;
              .title1{
                margin: 2vh auto 0 auto;
                width: 65%;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }
              .title2{
                text-align: justify;
                width: 90%;
                margin: 0 auto 0 auto;
                line-height: 3.194066749vh;
                font-size: 0.9vw;;
              }
              .title3{
                display: grid;
                grid-template-columns: minmax(0,1fr) minmax(0,1fr);
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.9vw;;
              }

            }

          }
        }


      }
    }
    .screenmake3{
      padding: 7.78739184177998vh 8.09763142692085vw;
      width: 100%;
      height: fit-content;
      background: white;
      ::v-deep .el-table .warning-row {
        background: oldlace;
      }

      ::v-deep .el-table .success-row {
        background: #f0f9eb;
      }
      .dv-scroll-board {
        height: 65vh;
        border-radius: 9px;
        cursor: pointer;
        background-color: #ebf1fd !important;
        color: #333333 !important;
      }

      ::v-deep .dv-scroll-board .rows :hover {
        background-color: #d8d9dc !important;
        color: #333333 !important;
      }
      .more{
        height: 7vh;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #7e89b9;
        cursor: pointer;
      }
      .more:hover{
        background-color: #d8d9dc !important;

      }
      .heighLight {
        margin: 0 auto;
        text-align: center;
        height:8.23733003708282vh;
        opacity: 1;
        font-size: 2.2060658579vw;
        color: #3a75fe;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        line-height: 4.23733003708282vh;
        width: fit-content;
        //border-bottom: #5798f2 5px solid;

      }

      .heightLight2 {
        margin:0 auto;
        text-align: center;
        height: 13.78739184177998vh;
        font-weight: 400;
        color: #2C3E50;
        line-height: 4.94437577255871vh;
        width: 60.9693818602vw;
        font-size: 1.0287117273vw;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
      }
      .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            background: transparent;
            border-radius: 9px;
            display: flex;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height:fit-content ;
            overflow:hidden;
            .imgDesign{
              width:fit-content;
              height: 35vw;
              opacity: 1;
            }
            .titleDesign{
              padding: 0 0 2.5248454883vh 3vw;
              margin:0 1vw auto auto;
              color: black;
              width: 50%;
              float: right;
              .title1{
                margin: 2vh auto 0 auto;
                width: 65%;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }
              .title2{
                text-align: justify;
                width: 90%;
                margin: 0 auto 0 auto;
                line-height: 3.194066749vh;
                font-size: 0.9vw;;
              }
              .title3{
                display: grid;
                grid-template-columns: minmax(0,1fr) minmax(0,1fr);
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.9vw;;
              }

            }

          }
        }


      }
    }
  }

}
.totalmakeHoleModel{
  background: #ebf1fd;
  overflow-x: hidden;
  width: 100vw;
  height: auto;
  position: relative;
  .makeHole1 {
    ////z-index::: 3;
    position: relative;
    width: 100%;
    //height: 100vh;
    .screen1 {
      ////z-index::: 3;
      position: relative;
      width: 100%;
      height: 300px;
      padding: 2.84301606922126vh 0  2.84301606922126vh 0;
      background-image: url("../assets/groundaboutM.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: transparent;
      /* Add any additional styling as needed */

      .layer2 {
        position: absolute;
        top: 100px;
        width: 100%;

        .title1 {
          width:fit-content;
          text-align: center;
          display: flex;
          margin:0 auto;
          justify-content: center;
          align-items: center;
          font-size: 22px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: white;
        }

        .title2 {
          margin: 15px auto;
          width: 90%;
          height: fit-content;
          font-size: 14px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #4B5A8B;
          line-height: 24px;
        }
        .title4{
          margin-top: 15px;

          height: 22px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          font-size:16px;
          color: #2f6dfd;
          line-height: 0px;
          font-style: normal;
          text-transform: none;
        }


      }
      .titlle3{
        position: absolute;
        bottom:30px;
        left:50%;
        transform: translateX(-60%);
        font-size: 14px;
        font-family: arial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 53px;
        width: fit-content;
        margin: 0 15px;
        padding: 5px 4.6875vw;
        height: 35px;
        color:white;
        background-color: #2f6eff;
        border: 1px solid #2f6eff;
        overflow: hidden;

      }

    }
    .screenmake0{
      ////z-index::: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: white;
      .heighLight{
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 20px;
        margin: 0 auto;
        padding: 0 0 5px 0;
        width: fit-content;
        border-bottom: #3163c5 5px solid;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 20px;
      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        position: relative;
        height: fit-content;
        margin-top:25px;
        .titleDesign{
          padding: 15px 0 2.5248454883vh 0;
          margin:0 1vw auto auto;
          color: black;
          width:100%;
          .title1{
            margin: 0 auto 0 auto;
            width: fit-content;
            font-size: 16px;
            font-weight: bold;
          }
          .title2{
            text-align: justify;
            width: 70%;
            margin: -125px auto 0 auto;
            line-height: 22px;
            font-size: 14px;
          }
          .title4{
            position: absolute;
            margin: 15px auto;
            left:50%;
            transform: translateX(-15%);
            width: 82%;
            z-index: 99999;
            color: white;
            height: fit-content;
            font-size:16px;
            font-weight: bold;
          }

        }
        ::v-deep .el-carousel__container{
          height: 65.87268232385661vh !important;
        }
        .el-carousel__item h3 {
          color: white;
          opacity: 0.75;
          margin: 0;
        }

        .el-carousel__item:nth-child(2n) {
          background-color: transparent;
        }

        .el-carousel__item:nth-child(2n+1) {
          background-color: transparent;
        }
        ::v-deep .el-carousel__container {
          position: relative;
          height: 48.83559950556242vh;
        }
        ::v-deep .el-carousel__arrow {
          display: none;
          border: none;
          outline: 0;
          padding: 0;
          margin: 0;
          cursor: pointer;
          transition: 0.3s;
          border-radius: 0;
          width: 1.15540150202195vw;
          height: 4.32632880098888vh;
          //background: #2168DB;
          color: #fff;
          position: absolute;
          top: 50%;
          ////z-index::: 10;
          transform: translateY(-50%);
          text-align: center;
          font-size: 12px;
        }
      }
    }
    .screenmake1{
      ////z-index::: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: transparent;
      .heighLight{
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 20px;
        margin: 0 auto;
        padding: 0 0 5px 0;
        width: fit-content;
        border-bottom: #3163c5 5px solid;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 20px;

      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        margin-top:4.78739184177998vh ;
        position: relative;
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        column-gap: 1.5vw;
        row-gap: 2vh;
        .card{
          transition:transform 0.3s ease-out;
          //cursor: pointer;
          //background-color: white;
          border-radius: 9px;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            background: transparent;
            border-radius: 9px;
            position: relative;
            height:fit-content;
            overflow:hidden;
            .imgDesign{
              width:20%;
              margin: 5% auto 0 auto;
              height:fit-content;
              opacity: 1;
            }
            .titleDesign{
              padding: 15px 0 2.5248454883vh 0;
              margin:0 1vw auto auto;
              color: black;
              width:100%;
              .title1{
                margin: 0 auto 0 auto;
                width: fit-content;
                font-size: 16px;
                font-weight: bold;
              }
              .title2{
                text-align: justify;
                width: 90%;
                margin: 15px auto 0 auto;
                line-height: 22px;
                font-size: 14px;
              }

            }

          }
        }
        .card:hover{
          .imgDesign {
            transform:scale(1.12);
          }
        }

        .card1{
          transition:transform 0.3s ease-out;
          //cursor: pointer;
          background-color: white;
          border-radius: 9px;
          width: 100%;
          min-height: 430px;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            background: transparent;
            border-radius: 9px;
            position: relative;
            height:fit-content;
            overflow:hidden;
            .imgDesign{
              width:20%;
              margin: 5% auto 0 auto;
              height:fit-content;
              opacity: 1;
            }
            .titleDesign{
              padding: 15px 0 2.5248454883vh 0;
              margin:0 1vw auto auto;
              color: black;
              width:100%;
              .title1{
                margin: 0 auto 0 auto;
                width: fit-content;
                text-align: center;
                font-size: 16px;
                height: 35px;
                font-weight: bold;
              }
              .title2{
                text-align: justify;
                width: 90%;
                margin: 15px auto 0 auto;
                line-height: 22px;
                font-size: 14px;
              }

            }

          }
        }
        .card1:hover{
          .imgDesign {
            transform:scale(1.12);
          }
        }
      }

    }
    .screenmake2{
      ////z-index::: 10;

      //left: 10.86077411900635vw;
      width: 100vw;
      height: fit-content;
      padding:20px 4.6875vw;
      background: transparent;
      .heighLight{
        text-align: center;
        height:fit-content;
        opacity: 1;
        font-size: 20px;
        margin: 0 auto;
        padding: 0 0 5px 0;
        width: fit-content;
        border-bottom: #3163c5 5px solid;
        //color: #53A3F7;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 700;
        line-height: 20px;

      }
      .heightLight2{
        margin:19px 2vw;
        text-align: justify;
        width: fit-content;
        height: fit-content;
        font-size:14px;
        font-family: Sarial, sans-serif, "Microsoft Yahei";
        font-weight: 400;
        color: #2C3E50;
        line-height: 20px;
      }

      .grid{
        margin-top:4.78739184177998vh ;
        position: relative;
        display: grid;
        grid-template-columns:minmax(0, 1fr);
        column-gap: 0;
        row-gap: 2vh;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .back{
            background: transparent;
            border-radius: 9px;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height:66.87144622991347vh ;
            overflow:hidden;
            .imgDesign{
              width:15%;
              margin: 5% auto 0 auto;
              height:fit-content;
              opacity: 1;
            }
            .titleDesign{
              padding: 4.5248454883vh 2.5248454883vw 2.5248454883vh 2%;
              margin:0 1vw auto auto;
              color: black;
              width: 45%;
              float: right;
              .title1{
                margin: 2vh auto 0 auto;
                width: 65%;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }
              .title2{
                text-align: justify;
                width: 90%;
                margin: 0 auto 0 auto;
                line-height: 3.194066749vh;
                font-size: 0.9vw;;
              }
              .title3{
                display: grid;
                grid-template-columns: minmax(0,1fr) minmax(0,1fr);
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.9vw;;
              }

            }

          }
        }


      }
    }

      .screenmake3{
        ////z-index::: 10;

        //left: 10.86077411900635vw;
        width: 100vw;
        height: fit-content;
        padding:20px 4.6875vw;
        background: white;
        .heighLight{
          text-align: center;
          height:fit-content;
          opacity: 1;
          font-size: 20px;
          margin: 0 auto;
          padding: 0 0 5px 0;
          width: fit-content;
          border-bottom: #3163c5 5px solid;
          //color: #53A3F7;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 700;
          line-height: 20px;

        }
        .heightLight2{
          margin:19px 2vw;
          text-align: justify;
          width: fit-content;
          height: fit-content;
          font-size:14px;
          font-family: Sarial, sans-serif, "Microsoft Yahei";
          font-weight: 400;
          color: #2C3E50;
          line-height: 20px;
        }

        .grid{
        margin-top:2.78739184177998vh ;
        position: relative;
        .card{
          //cursor: pointer;
          width: 100%;
          height:fit-content ;
          padding:0.98887515451174vh 0.69324090121317vw 0.98887515451174vh 0.69324090121317vw;
          opacity: 1;

          .dv-scroll-board {
            height: 350px;
            border-radius: 9px;
            cursor: pointer;
            background-color: #ebf1fd !important;
            color: black !important;
          }

          .more{
            height:32px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #7e89b9;
            cursor: pointer;
          }


          .back{
            background: transparent;
            border-radius: 9px;
            position: relative;
            //box-shadow: 2px 0 12px 0px #c9d9f5;
            height:fit-content;
            overflow:hidden;
            .titleDesign{
              padding: 0 2% 2.5248454883vh 2%;
              margin:0 auto auto auto;
              color: black;
              width: 100%;
              float: right;
              .title1{
                margin: 2vh auto 0 auto;
                width: 100%;
                font-size: 1.1709416522vw;
                font-weight: bold;
              }
              .title2{
                text-align: justify;
                width: 90%;
                margin: 0 auto 0 auto;
                line-height: 3.194066749vh;
                font-size: 0.9vw;;
              }
              .title3{
                display: grid;
                grid-template-columns: minmax(0,1fr) minmax(0,1fr);
                margin-top: 2vh;
                line-height: 3.194066749vh;
                font-size: 0.9vw;;
              }

            }

          }
        }


      }
    }
  }

}
</style>
